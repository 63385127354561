import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Autocomplete from "lib/Select/Autocomplete";
import { useInventoryDispatch, useInventoryState } from "providers";
import { FormWrapper } from "components/Forms";
import { useAssetsState } from "providers/AssetsProvider";
import {
  AssetStatusIDFormField,
  BornOnUTCFormField,
  CIDFormField,
  DescriptionFormField,
  FieldFormField,
} from "./FormFields";
import { IAnimalAsset, IAnimalBreed } from "types/IAssetType";
import { ISelectOption, IUrlParams } from "types";
import AssetGroupSelect from "./AssetGroupSelect";
import { useOrgState } from "providers";
import { useAnimalBreeds } from "api/assets/useAnimalBreeds";
import { useParams } from "react-router-dom";
import { useAssetGeodataById } from "api/assets/useAssetGeodataById";

export function filterOption(data: IAnimalBreed, input: string) {
  const keys = ["name", "assetAnimalTypeName"] as (
    | "name"
    | "assetAnimalTypeName"
  )[];
  const str = keys.reduce((prev, curr, idx) => {
    return (prev += `${!idx ? "" : " "}${data[curr]?.toLowerCase()}`);
  }, "");
  const parts = input.split(" ");
  const matches =
    parts.filter((part) => {
      return str.includes(part?.toLowerCase());
    }).length === parts.length;
  return matches ? data : null;
}
const defaultValues = {
  id: null,
  fieldId: null,
  assetGroupId: null,
  assetAnimalBreedId: null,
  isFemale: false,
  bornOnUtc: null,
  description: null,
  cid: null,
  assetStatusId: null,
  geometry: null,
} as IAnimalAsset;

export default function AnimalAssetForm({
  assetStatuses,
  handleDelete,
  handleSave,
  getCancelHref,
}: {
  assetStatuses: ISelectOption[];
  handleSave: (_d: IAnimalAsset, reset: () => void, isDirty: boolean) => void;
  handleDelete: (
    id: string,
    reset: () => void,
    defaultValues: IAnimalAsset
  ) => void;
  getCancelHref: () => string;
}) {
  const { t } = useTranslation();
  const { org, season } = useOrgState();
  const { drawData } = useInventoryState();
  const { setDrawOptions } = useInventoryDispatch();
  const breedsQ = useAnimalBreeds(org);
  const {
    // currentEditFtr,
    deleteInventoryItemState,
    saveInventoryItemState,
  } = useAssetsState();
  const { itemId } = useParams<IUrlParams>();
  const geodataQ = useAssetGeodataById({
    type: "animal",
    org,
    seasonId: season?.id,
    id: itemId,
  });
  const currentEditFtr = geodataQ.data?.features?.[0];
  const methods = useForm({
    defaultValues: { ...defaultValues },
  });
  const {
    handleSubmit,
    register,
    reset,
    formState,
    watch,
    control,
    // setValue,
    errors,
  } = methods;
  const { id, isFemale, name: animalName } = watch();

  useEffect(() => {
    setDrawOptions({
      controls: {
        trash: true,
        point: true,
      },
      defaultMode: "draw_point",
    });
  }, [setDrawOptions]);

  async function onSave(d: IAnimalAsset) {
    d.geometry = d.geometry ? JSON.parse(d.geometry) : "";
    // server doesn't want these props if not set
    if (!d.id) {
      delete d.id;
    }
    if (!d.assetGroupId) {
      delete d.assetGroupId;
    }
    return handleSave(d, reset, formState.isDirty);
  }
  return (
    <FormWrapper
      geometryRequired={false}
      // TODO:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      methods={methods}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      cancelHref={getCancelHref()}
      saveState={saveInventoryItemState}
      deleteState={deleteInventoryItemState}
      onDelete={() => {
        handleDelete(id, reset, defaultValues);
      }}
      onSubmit={handleSubmit(onSave)}
    >
      <Stack spacing={2}>
        <FieldFormField control={control} />
        <Controller
          name="assetGroupId"
          control={control}
          render={(props: {
            value: string;
            onChange: (_id: string) => void;
          }) => (
            <AssetGroupSelect
              value={props.value}
              onChange={(id) => {
                props.onChange(id);
              }}
            />
          )}
        />
        <TextField
          fullWidth
          label={`${t("common.name")}`}
          value={animalName ?? ""}
          inputProps={{
            name: "name",
            ref: register,
            maxLength: 50,
          }}
        />
        <Controller
          name="assetAnimalBreedId"
          rules={{ required: true }}
          control={control}
          render={(props: {
            value: string;
            onChange: (_id: string) => void;
          }) => (
            <Autocomplete
              label={`${t("common.breed")} *`}
              disableClearable={false}
              error={!!errors?.assetAnimalBreedId}
              options={breedsQ.data || []}
              filterOptions={(data: IAnimalBreed[], input) => {
                return data.filter((d) => {
                  return filterOption(d, input.inputValue);
                });
              }}
              groupBy={(o) => o.assetAnimalTypeName}
              value={breedsQ.data?.find((f) => f.id === props.value) ?? null}
              onChange={(_e, item) => {
                const opt = item as IAnimalBreed;
                props.onChange(opt?.id || "");
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="isFemale"
          render={(props) => {
            return (
              <FormControl>
                <FormLabel id="gender-radio">
                  {`${t("inventory.assets.sex")} *`}
                </FormLabel>
                <RadioGroup
                  ref={register}
                  row
                  value={isFemale}
                  onChange={(e) => {
                    props.onChange(e.target.value === "true");
                  }}
                  aria-labelledby="gender-radio"
                  name="isFemale"
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label={t("inventory.assets.male")}
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label={t("inventory.assets.female")}
                  />
                </RadioGroup>
              </FormControl>
            );
          }}
        />
        <BornOnUTCFormField
          control={control}
          label={`${t("inventory.assets.bornOn")}`}
        />
        <CIDFormField register={register} />
        <AssetStatusIDFormField
          assetStatusOpts={assetStatuses}
          control={control}
          isError={Boolean(errors?.assetStatusId)}
        />
        <DescriptionFormField register={register} />
      </Stack>
    </FormWrapper>
  );
}
