import React, { useCallback, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import { useAssetsDispatch, useInventoryDispatch } from "providers";
import { useURLSearchParams } from "components";
import PlantAssetForm from "./PlantAssetForm";
import FixedAssetForm from "./FixedAssetForm";
import AnimalAssetForm from "./AnimalAssetForm";
import { IUrlParams } from "types";
import useOrgLookupRQ from "components/useOrgLookupRQ";
import { useQueryClient } from "@tanstack/react-query";

export default function AssetForm() {
  const { org, rootUrl } = useOrgState();
  const { type, subtype, fieldId, itemId } = useParams<IUrlParams>();
  const history = useHistory();
  const queryClient = useQueryClient();
  const {
    resetSave,
    resetDelete,
    deleteInventoryItem,
    saveInventoryItem,
    resetAssetsGeodata,
  } = useAssetsDispatch();
  const invalidateQueryKey = React.useMemo(
    () => ["organization", org, "assets", type],
    [org, type]
  );
  const { data: assetStatuses } = useOrgLookupRQ(
    `/${org}/lookups/assetstatuses`
  );

  const { enableDraw, disableDraw } = useInventoryDispatch();
  const baseRoute = `${rootUrl}/inventory/assets/${type}`;
  const query = useURLSearchParams();
  const groupId = query.get("groupId");
  const url = `${baseRoute}/${subtype}/${fieldId}/items/${itemId}${
    groupId ? `?groupId=${groupId}` : ""
  }`;
  const handleSave = useCallback(
    async (data, reset, isDirty) => {
      if (!isDirty) {
        history.push(url);
        return false;
      }
      const dataProp =
        type === "plant"
          ? "crop"
          : type === "fixed"
          ? "fixedAssetType"
          : type === "animal"
          ? "assetAnimalType"
          : "";
      const res = await saveInventoryItem(data);
      if (!res.isError) {
        queryClient.invalidateQueries({
          queryKey: invalidateQueryKey,
        });
        reset(data);
        const ftrs = res?.data?.features || [];
        const assetId = ftrs[0]?.id;
        const ftrType = ftrs[0]?.properties[dataProp];
        const fldId = ftrs[0]?.properties?.fieldId;
        const grpId = ftrs[0]?.properties?.assetGroupId;
        const saveUrl = `${baseRoute}/${ftrType}/${fldId}/items/${assetId}${
          grpId ? `?groupId=${grpId}` : ""
        }`;
        history.push(saveUrl);
      }
      return res;
    },
    [
      type,
      saveInventoryItem,
      history,
      url,
      queryClient,
      invalidateQueryKey,
      baseRoute,
    ]
  );

  const handleDelete = useCallback(
    async (id, reset, defaultValues) => {
      const res = await deleteInventoryItem(id);
      if (!res.isError) {
        queryClient.invalidateQueries({
          queryKey: invalidateQueryKey,
        });
        resetAssetsGeodata();
        reset(defaultValues);
        history.push(baseRoute);
      }
    },
    [
      deleteInventoryItem,
      queryClient,
      invalidateQueryKey,
      resetAssetsGeodata,
      history,
      baseRoute,
    ]
  );

  const getCancelHref = useCallback(() => {
    if (!subtype) {
      return baseRoute;
    }
    return url;
  }, [url, baseRoute, subtype]);

  // when leaving form, reset the fetch state
  useEffect(() => {
    return () => {
      resetSave();
      resetDelete();
    };
  }, [resetSave, resetDelete]);
  // show the map on mount
  useEffect(() => {
    enableDraw();
    return disableDraw;
  }, [enableDraw, disableDraw]);

  return (
    <>
      {type === "plant" ? (
        <PlantAssetForm
          assetStatuses={assetStatuses}
          handleSave={handleSave}
          handleDelete={handleDelete}
          getCancelHref={getCancelHref}
        />
      ) : type === "fixed" ? (
        <FixedAssetForm
          assetStatuses={assetStatuses}
          handleSave={handleSave}
          handleDelete={handleDelete}
          getCancelHref={getCancelHref}
        />
      ) : type === "animal" ? (
        <AnimalAssetForm
          assetStatuses={assetStatuses}
          handleSave={handleSave}
          handleDelete={handleDelete}
          getCancelHref={getCancelHref}
        />
      ) : null}
    </>
  );
}
