import { Route, Switch, useParams } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import { AssetsProvider } from "providers/AssetsProvider";
import { useURLSearchParams } from "components";
import { AssetsView } from "./AssetsView";
import { IUrlParams } from "types";

export function Assets() {
  const { type, subtype, fieldId } = useParams<IUrlParams>();
  const { org, season } = useOrgState();
  const query = useURLSearchParams();

  return (
    <AssetsProvider
      org={org}
      seasonId={season?.id}
      type={type}
      subtype={subtype}
      fieldId={fieldId}
      groupId={query.get("groupId")}
    >
      <Switch>
        <Route
          path={
            "/:org/:season/inventory/assets/:type/:subtype?/:fieldId/items/:itemId"
          }
        >
          <AssetsView />
        </Route>
        <Route
          path={"/:org/:season/inventory/assets/:type/:subtype?/:fieldId?"}
        >
          <AssetsView />
        </Route>
      </Switch>
    </AssetsProvider>
  );
}
