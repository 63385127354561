import { useOrgState } from "providers/OrgProvider";
import { useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { useAssetColumns } from "components/useAssetColumns";
import { useAssetsByTypeSummary } from "api/assets/useAssetSummaries";
import DataTable from "lib/DataTable";
import { useEffect } from "react";
import { useInventoryDispatch } from "providers";
import ChevronRight from "@mui/icons-material/ChevronRight";

export function AssetsByTypeTable() {
  const { type } = useParams();
  const { org, rootUrl } = useOrgState();
  const { setInventoryFtrs } = useInventoryDispatch();
  const { subtype: subtypeColumns } = useAssetColumns();
  const dict = {
    plant: subtypeColumns.plant,
    fixed: subtypeColumns.fixed,
    equipment: subtypeColumns.equipment,
    animal: subtypeColumns.animal,
  };
  const query = useAssetsByTypeSummary(org, type);

  const actionsColumn = {
    Header: "",
    id: "actions",
    isSortable: false,
    styles: {
      width: "50px",
    },
    accessor: function Cell(row) {
      // const y = row.subtype.toLowerCase().replaceAll(' ', '-');
      const encoded = encodeURIComponent(row.subtype);
      return (
        <IconButton
          style={{ color: "inherit" }}
          href={`${rootUrl}/inventory/assets/${type}/${encoded}`}
        >
          <ChevronRight />
        </IconButton>
      );
    },
  };

  const getColumnsBySubtype = (type) => {
    return [actionsColumn].concat(dict[type] || []);
  };

  useEffect(() => {
    // no geodata at this level
    setInventoryFtrs(null);
  }, [setInventoryFtrs]);

  return (
    <DataTable
      isLoading={query.isLoading}
      paging={query?.data?.length > 100}
      data={query?.data || []}
      columns={getColumnsBySubtype(type)}
    />
  );
}
