import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import {
  useAssetsState,
  useInventoryDispatch,
  useInventoryState,
} from "providers";
import { FormWrapper } from "components";
import {
  AssetStatusIDFormField,
  BornOnUTCFormField,
  CIDFormField,
  CropTypeIDFormField,
  DescriptionFormField,
  FieldFormField,
} from "./FormFields";
import { IPlantAsset } from "types/IAssetType";
import { ISelectOption, IUrlParams } from "types";
import { useOrgState } from "providers";
import { useParams } from "react-router-dom";
import { useAssetGeodataById } from "api/assets/useAssetGeodataById";

const defaultValues = {
  id: "",
  fieldId: "",
  cropTypeId: "",
  bornOnUtc: "",
  description: "",
  cid: "",
  assetStatusId: "",
  geometry: "",
} as IPlantAsset;

export default function PlantAssetForm({
  assetStatuses,
  handleSave,
  handleDelete,
  getCancelHref,
}: {
  assetStatuses: ISelectOption[];
  handleSave: (_d: IPlantAsset, reset: () => void, isDirty: boolean) => void;
  handleDelete: (
    id: string,
    reset: () => void,
    defaultValues: IPlantAsset
  ) => void;
  getCancelHref: () => string;
}) {
  const { t } = useTranslation();
  const { setDrawOptions } = useInventoryDispatch();
  const { drawData } = useInventoryState();
  const { org, season } = useOrgState();
  const { itemId } = useParams<IUrlParams>();
  const geodataQ = useAssetGeodataById({
    type: "plant",
    org,
    seasonId: season?.id,
    id: itemId,
  });
  const currentEditFtr = geodataQ.data?.features[0];
  const { deleteInventoryItemState, saveInventoryItemState } = useAssetsState();

  const methods = useForm({
    defaultValues: { ...defaultValues },
  });
  const {
    handleSubmit,
    register,
    reset,
    formState,
    watch,
    control,
    errors,
  } = methods;
  const { id } = watch();

  useEffect(() => {
    setDrawOptions({
      controls: {
        trash: true,
        point: true,
      },
      defaultMode: "draw_point",
    });
  }, [setDrawOptions]);

  async function onSave(d: IPlantAsset) {
    d.geometry = d.geometry ? JSON.parse(d.geometry) : "";
    // server doesn't want these props if not set
    if (!d.id) {
      delete d.id;
    }
    if (!d.fieldId) {
      delete d.fieldId;
    }
    if (!d.assetGroupId) {
      delete d.assetGroupId;
    }
    return handleSave(d, reset, formState.isDirty);
  }
  return (
    <FormWrapper
      // TODO:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      methods={methods}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      // cancelHref={itemId ? `${baseRoute}/${itemId}` : baseRoute}
      cancelHref={getCancelHref()}
      saveState={saveInventoryItemState}
      deleteState={deleteInventoryItemState}
      onDelete={() => {
        return handleDelete(id, reset, defaultValues);
      }}
      onSubmit={handleSubmit(onSave)}
    >
      <Stack spacing={2}>
        <FieldFormField control={control} />
        <CIDFormField register={register} required />
        <CropTypeIDFormField
          control={control}
          isError={Boolean(errors?.cropTypeId)}
        />
        <BornOnUTCFormField
          control={control}
          label={`${t("inventory.assets.plantedDate")}`}
        />
        <AssetStatusIDFormField
          control={control}
          assetStatusOpts={assetStatuses}
          isError={Boolean(errors?.assetStatusId)}
        />
        <DescriptionFormField register={register} />
        {errors?.geometry ? (
          <Typography color="error.main">
            {t("common.geometryRequired")}
          </Typography>
        ) : null}
      </Stack>
    </FormWrapper>
  );
}
