// import { useCallback, useState } from 'react';
import useFetchWithToken from "components/useFetchWithToken";
import { FeatureCollection } from "geojson";
import { useCallback } from "react";

export function useInventorySummaries({
  org,
  inventoryType,
  subtype,
  seasonId,
  colorProp,
  dataProp,
}: {
  org: string;
  inventoryType: string;
  subtype: string;
  seasonId: string;
  colorProp?: string;
  dataProp?: string;
}) {
  const {
    state: listByTypeState,
    fetchData: fetchListByType,
  } = useFetchWithToken();
  const {
    state: listBySubtypeState,
    fetchData: fetchListBySubtype,
    setDataState: setListBySubtype,
  } = useFetchWithToken();

  const {
    state: listBySubtypeAndFieldState,
    fetchData: fetchListBySubtypeAndField,
    setDataState: setListBySubtypeAndField,
  } = useFetchWithToken();

  const {
    state: mapThemesState,
    fetchData: fetchMapThemes,
  } = useFetchWithToken<{ [index: string]: unknown; count: number }[]>();

  const {
    state: geodataState,
    fetchData: fetchGeodata,
    setDataState: setGeodata,
  } = useFetchWithToken<FeatureCollection>();

  const {
    state: deleteItemState,
    fetchData: deleteItemById,
    resetFetchState: resetDeleteItemState,
  } = useFetchWithToken<void>();

  const {
    state: saveInventoryItemState,
    fetchData: saveInventoryItem,
    resetFetchState: resetSaveItemState,
  } = useFetchWithToken<FeatureCollection>();

  const fetchMapThemesBySeason = useCallback(() => {
    return fetchMapThemes(
      `/${org}/${inventoryType}${
        subtype ? `/${subtype}` : ""
      }/season/${seasonId}/mapthemes`,
      {
        transformResponse: (data) => {
          const total = data?.reduce((item, next) => {
            const cnt = item + next.count;
            return cnt;
          }, 0);
          const items = data?.reduce((item, next) => {
            const percent = Number(((next.count / total) * 100).toFixed(2));
            item.push({
              percent,
              name: next[dataProp],
              ...next,
              color: next[colorProp],
            });
            return item;
          }, []);
          return items;
        },
      }
    );
  }, [
    seasonId,
    org,
    inventoryType,
    subtype,
    fetchMapThemes,
    colorProp,
    dataProp,
  ]);

  const fetchGeodataByFieldAndQueryParams = useCallback(
    async (params = {}) => {
      const keys = Object.keys(params);
      let queryStr = "";
      keys.forEach((k, i) => {
        queryStr += `${i === 0 ? "?" : "&"}`;
        queryStr += `${k}=${params[k]}`;
      });
      return fetchGeodata(
        `/${org}/${inventoryType}${
          subtype ? `/${subtype}` : ""
        }/geodata/fields${queryStr}`
      );
    },
    [fetchGeodata, org, subtype, inventoryType]
  );

  const fetchGeodataByItemId = useCallback(
    (id) => {
      return fetchGeodata(
        `/${org}/${inventoryType}${subtype ? `/${subtype}` : ""}/geodata/${id}`
      );
    },
    [fetchGeodata, org, subtype, inventoryType]
  );

  const deleteItem = useCallback(
    (id) => {
      return deleteItemById(
        `/${org}/${inventoryType}${subtype ? `/${subtype}` : ""}/geodata/${id}`,
        {
          method: "DELETE",
        }
      );
    },
    [deleteItemById, org, subtype, inventoryType]
  );

  const saveItem = useCallback(
    (data) => {
      const { geometry, ...rest } = data;
      const method = data.id ? "PUT" : "POST";
      return saveInventoryItem(
        `/${org}/${inventoryType}${subtype ? `/${subtype}` : ""}/geodata`,
        {
          method,
          body: JSON.stringify({
            // prevent setting geometry prop if none
            ...(geometry && {
              geometry: JSON.stringify(geometry),
            }),
            ...rest,
          }),
        }
      );
    },
    [saveInventoryItem, org, subtype, inventoryType]
  );

  return {
    fetchListByType: useCallback(() => {
      return fetchListByType(`/${org}/${inventoryType}/${subtype}/summary`);
    }, [fetchListByType, org, subtype, inventoryType]),
    listByTypeState,

    fetchListBySubtype: useCallback(
      (subtypeId) => {
        return fetchListBySubtype(
          `/${org}/${inventoryType}/${subtype}/${subtypeId}/season/${seasonId}/summary`
        );
      },
      [fetchListBySubtype, org, subtype, seasonId, inventoryType]
    ),
    resetListBySubtype: useCallback(
      (d) => {
        setListBySubtype(d);
      },
      [setListBySubtype]
    ),
    listBySubtypeState,

    fetchListBySubtypeAndField: useCallback(
      (subtypeId, fieldId, groupId) => {
        let url = `/${org}/${inventoryType}/${subtype}/${subtypeId}/fields/${fieldId}/summary`;
        if (groupId) {
          url += `?assetGroupId=${groupId}`;
        }
        return fetchListBySubtypeAndField(url);
      },
      [fetchListBySubtypeAndField, org, subtype, inventoryType]
    ),
    resetListbySubtypeAndField: useCallback(
      (d) => {
        setListBySubtypeAndField(d);
      },
      [setListBySubtypeAndField]
    ),
    listBySubtypeAndFieldState,

    fetchMapThemesBySeason,
    mapThemesState,

    deleteInventoryItem: deleteItem,
    resetDeleteItemState,
    deleteInventoryItemState: deleteItemState,

    saveInventoryItem: saveItem,
    resetSaveItemState,
    saveInventoryItemState,

    fetchGeodataByFieldAndQueryParams,
    fetchGeodataByItemId,
    geodataState,
    resetGeodata: useCallback(
      (data?: FeatureCollection) => {
        setGeodata(data);
      },
      [setGeodata]
    ),
  };
}
