import axios from "axios";
import { useCallback, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

export interface IAssetTypeSummaryItem {
  assetType: string;
  asset: string;
  assetEn: string;
  color: string;
  count: number;
  field: string;
  fieldId: string;
  subtype: string;
  subtypeId?: string;
}

export interface IAssetSubtypeSummaryItem extends IAssetTypeSummaryItem {
  subtype: string;
  subtypeId: string;
}

export function useAssetsSeasonSummary(org: string, seasonId: string) {
  const queryKey = useMemo(
    () => ["organization", org, "assets", "seasonId", seasonId, "summary"],
    [org, seasonId]
  );

  const getSummary = useCallback(async () => {
    const res = await axios.get<IAssetTypeSummaryItem[]>(
      `/${org}/assets/season/${seasonId}/summary`
    );
    return res.data;
  }, [seasonId, org]);

  return useQuery({
    queryKey,
    queryFn: getSummary,
    enabled: Boolean(org && seasonId),
  });
}

export function useAssetsByTypeSummary(org: string, assetType: string) {
  const queryKey = useMemo(
    () => ["organization", org, `assets`, assetType, "summary"],
    [assetType, org]
  );
  const getTypeSummaryList = useCallback(async () => {
    const res = await axios.get<IAssetTypeSummaryItem[]>(
      `/${org}/assets/${assetType}/summary`
    );

    return res.data;
  }, [assetType, org]);

  return useQuery({
    queryKey,
    queryFn: getTypeSummaryList,
    enabled: Boolean(org && assetType),
  });
}

export function useAssetsBySubtypeSummary(
  org: string,
  assetType: string,
  subtypeId: string,
  seasonId: string
) {
  const queryKey = useMemo(
    () => [
      "organization",
      org,
      `assets`,
      assetType,
      "subtypeId",
      subtypeId,
      "seasonId",
      seasonId,
      "summary",
    ],
    [assetType, org, seasonId, subtypeId]
  );

  const getSubtypeSummaryList = useCallback(async () => {
    const res = await axios.get<IAssetSubtypeSummaryItem[]>(
      `/${org}/assets/${assetType}/${subtypeId}/season/${seasonId}/summary`
    );

    return res?.data;
  }, [assetType, org, seasonId, subtypeId]);

  return useQuery({
    queryKey,
    queryFn: getSubtypeSummaryList,
    enabled: Boolean(org && assetType && subtypeId && seasonId),
  });
}

export function useAssetsByFieldAndGroup(
  org: string,
  assetType: string,
  subtypeId: string,
  fieldId: string,
  assetGroupId?: string
) {
  const queryKey = useMemo(
    () => [
      "organization",
      org,
      `assets`,
      assetType,
      "subtypeId",
      subtypeId,
      "fieldId",
      fieldId,
      "assetGroupId",
      assetGroupId,
      "summary",
    ],
    [assetGroupId, assetType, fieldId, org, subtypeId]
  );

  const getSubtypeSummaryList = useCallback(async () => {
    let url = `/${org}/assets/${assetType}/${subtypeId}/fields/${fieldId}/summary`;
    if (assetGroupId) {
      url += `?assetGroupId=${assetGroupId}`;
    }
    const res = await axios.get<IAssetSubtypeSummaryItem[]>(url);

    return res?.data;
  }, [assetGroupId, assetType, fieldId, org, subtypeId]);
  return useQuery({
    queryKey,
    queryFn: getSubtypeSummaryList,
    enabled: Boolean(org && assetType && subtypeId && fieldId),
  });
}
