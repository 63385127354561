import { useEffect } from "react";
import usePrevious from "components/usePrevious";
import useFetchWithToken from "components/useFetchWithToken";

interface IOrgLookup {
  id: string;
  name: string;
  nameEn: string;
  value: string;
  label: string;
}

function defaultFormatResponse(res: IOrgLookup[]) {
  if (res && res.length) {
    res.forEach((r) => {
      r.value = r.id;
      r.label = r.name;
    });
  }

  return res;
}

export function useOrgLookup({
  org,
  url,
  initData,
  formatResponse = defaultFormatResponse,
}: {
  org: string;
  url: string;
  initData?: IOrgLookup[];
  formatResponse?: (_d: IOrgLookup[]) => IOrgLookup[];
}) {
  const { state, fetchData, setDataState, abort } = useFetchWithToken<
    IOrgLookup[]
  >(initData);

  const prevOrg = usePrevious(org);

  // useEffect(() => {
  //   if (
  //     org !== prevOrg &&
  //     url &&
  //     !state.data &&
  //     !state.isLoading &&
  //     !state.isError
  //   ) {
  //     abort();
  //     fetchData(`${url}`);
  //   }
  // }, [url, org, fetchData, prevOrg, abort, state]);
  // RESET WHEN ORG CHANGES
  useEffect(() => {
    if (org && org !== prevOrg) {
      abort();
      setDataState(null);
      fetchData(`/${url}`, {
        transformResponse: formatResponse,
      });
    }
  }, [org, prevOrg, abort, setDataState, fetchData, url, formatResponse]);

  return {
    reset: (d: IOrgLookup[]) => {
      abort();
      setDataState(d);
    },
    state,
    items: state.data as IOrgLookup[],
    fetchData: async () => {
      return fetchData(`/${url}`);
    },
  };
}
