export const assetKeys = {
  all: (org: string) => ["organization", org, "assets"] as const,
  byId: (org: string, id: string) => {
    return ["organization", org, "assets", id] as const;
  },
  photosById: (org: string, id: string) =>
    ["organization", org, "assets", id, "photos"] as const,
  geodataById: (org: string, type: string, id: string) =>
    ["organization", org, "assets", type, "geodata", id] as const,
  geodataByParams: (
    org: string,
    type: string,
    params: Record<string, unknown>
  ) => ["organization", org, "assets", type, "geodata", params] as const,
};

export const eventKeys = {
  allById: (org: string, id: string) =>
    ["organization", org, "assets", id, "assetevents"] as const,
  photosById: (org: string, id: string) =>
    ["organization", org, "assetevents", id, "photos"] as const,
  byId: (org: string, id: string) => {
    return ["organization", org, "assetevents", id] as const;
  },
};
