import Stack from "@mui/material/Stack";
// import Typography from "@mui/material/Typography";
import { Control, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useInventoryDispatch } from "providers/InventoryProvider";
import { IField, ISubfield, IUrlParams } from "types";
import Autocomplete from "lib/Select/Autocomplete";
import { useFieldsState } from "providers";
import { useSubfields } from "api/useSubfields";
import { FeatureCollection } from "geojson";
import { useOrgState } from "providers";
import { useMemo } from "react";
import { useFields } from "api/fields/useFields";

export default function FieldsAndSubfields({
  ids,
  control,
}: {
  ids: {
    subfieldIds: string[];
    fieldIds: string[];
  };
  control: Control;
}) {
  const { t } = useTranslation();
  const { itemId } = useParams<IUrlParams>();
  const { org, season } = useOrgState();
  const { fieldsGeodataState } = useFieldsState() as {
    fieldsGeodataState: {
      data: FeatureCollection;
    };
  };
  const subfieldsQ = useSubfields(org, season?.id);
  const fieldsQ = useFields({ org, seasonId: season?.id });
  const { fitInventoryBounds } = useInventoryDispatch();

  const fieldOpts = useMemo(() => {
    const data =
      fieldsQ.data
        ?.filter((d) => {
          const hasSub = ids?.subfieldIds?.length
            ? subfieldsQ.data?.find((sf) => {
                return ids.subfieldIds.includes(sf.id) && d.id === sf.fieldId;
              })
            : false;
          return !hasSub;
        })
        .sort((a, b) => (a.farm > b.farm ? 1 : b.farm > a.farm ? -1 : 0)) || [];
    return data;
  }, [fieldsQ.data, ids?.subfieldIds, subfieldsQ.data]);

  const subfieldOpts = useMemo(() => {
    return (
      subfieldsQ.data
        ?.filter((d) => !ids?.fieldIds?.includes(d.fieldId))
        .sort((a, b) => (a.field > b.field ? 1 : b.field > a.field ? -1 : 0)) ||
      []
    );
  }, [ids?.fieldIds, subfieldsQ.data]);
  return (
    <Stack sx={{ mt: 2 }} gap={2}>
      {!itemId ? (
        <>
          <Controller
            name="fieldIds"
            control={control}
            render={({
              onChange,
              value,
            }: {
              onChange: (_ids: string) => void;
              value: string;
            }) => (
              <Autocomplete
                label={`${t("inventory.fields.title")} *`}
                disableClearable={false}
                id="field-select"
                options={fieldOpts || []}
                InputProps={{
                  required: !ids?.fieldIds?.length && !ids?.subfieldIds?.length,
                }}
                multiple
                getOptionLabel={(o) => (typeof o !== "string" ? o.name : o)}
                groupBy={(o) => o.farm}
                onChange={(_e, items) => {
                  const flds = items as IField[];
                  const ids = flds?.map((f) => f.id);
                  const geo = fieldsGeodataState.data.features.filter((f) =>
                    ids.includes(f.properties.id)
                  );
                  fitInventoryBounds({
                    geojson: {
                      type: "FeatureCollection",
                      features: geo,
                    },
                  });

                  onChange(JSON.stringify(ids));
                }}
                value={fieldOpts?.filter((d) => value?.includes(d.id)) ?? []}
              />
            )}
          />
          <Controller
            name="subfieldIds"
            control={control}
            render={(props) => (
              <Autocomplete
                label={`${t("inventory.subfields.title")} *`}
                disableClearable={false}
                id="subfield-select"
                options={subfieldOpts}
                getOptionLabel={(o: ISubfield) => o.cid}
                InputProps={{
                  required: !ids?.fieldIds?.length && !ids?.subfieldIds?.length,
                }}
                multiple
                groupBy={(o) => o.field}
                onChange={(_e, items) => {
                  const subflds = items as ISubfield[];
                  props.onChange(JSON.stringify(subflds?.map((f) => f.id)));
                }}
                value={
                  subfieldOpts?.filter((d) => props.value?.includes(d.id)) ?? []
                }
              />
            )}
          />
        </>
      ) : null}
    </Stack>
  );
}
