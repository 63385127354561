import React, { useCallback } from "react";
import useFetchWithToken from "components/useFetchWithToken";
import { useInventoryType } from "views/InventoryView/useInventoryType";
import { useOrgLookup } from "components/useOrgLookup";
import { useInventorySummaries } from "views/InventoryView/useInventorySummaries";
import {
  useAssetsBySubtypeSummary,
  useAssetsByTypeSummary,
} from "api/assets/useAssetSummaries";
import useOrgLookupRQ from "components/useOrgLookupRQ";
import { IAssetRecord } from "types/IAssetRecord";
import { FeatureCollection } from "geojson";
import { IFetchState, ISelectOption } from "types";

interface FixedAssetType {
  color: string;
  geometryType: string;
  id: string;
  name: string;
}

const AssetsStateContext = React.createContext<
  Record<string, unknown> & {
    fixedAssetTypes?: FixedAssetType[];
    deleteInventoryItemState?: IFetchState<void>;
    saveInventoryItemState?: IFetchState<unknown>;
    currentAssetRecords?: IAssetRecord[];
    isFetchingData?: boolean;
    subtypeId?: string;
    assetGroups?: ISelectOption[];
  }
>({});

type Context = {
  [index: string]: (_first?: unknown, _second?: unknown) => unknown;
  fetchListBySeason: (
    _d: unknown
  ) => Promise<IFetchState<Record<string, unknown>[]>>;
  createGroup: (_d: {
    name: string;
  }) => Promise<IFetchState<{ nameEn: string; name: string; id: string }>>;
  saveInventoryItem?: (_d: {
    name: string;
  }) => Promise<IFetchState<FeatureCollection>>;
  deleteInventoryItem?: (_id: string) => Promise<IFetchState<void>>;
};

const AssetsDispatchContext = React.createContext<Context>({
  createGroup: null,
  fetchListBySeason: null,
});

function AssetsProvider({
  children,
  org,
  seasonId,
  type,
  groupId,
  subtype,
  fieldId,
}: {
  children: React.ReactNode;
  org: string;
  seasonId: string;
  type?: string;
  groupId?: string;
  subtype?: string;
  fieldId?: string;
}) {
  const { items: assetGroups, reset: resetGroups } = useOrgLookup({
    org,
    url: `${org}/lookups/assetgroups`,
  });
  const { data: assetEventTypes } = useOrgLookupRQ(
    `/${org}/lookups/asseteventtypes`
  );
  const { data: fixedAssetTypes } = useOrgLookupRQ<FixedAssetType>(
    `/${org}/lookups/assetfixedtypes`
  );

  const { fetchData: createGroup } = useFetchWithToken<{
    name: string;
    nameEn: string;
    id: string;
  }>();

  const assetsByTypeQuery = useAssetsByTypeSummary(org, type);

  const groupName = groupId
    ? assetGroups?.find((d) => d.id === groupId)?.name
    : null;

  const subtypeObj = assetsByTypeQuery.data?.find(
    (at: { subtype: string }) => at.subtype === decodeURIComponent(subtype)
  );

  const assetsBySubtypeQuery = useAssetsBySubtypeSummary(
    org,
    type,
    subtypeObj?.subtypeId,
    seasonId
  );
  const fieldObj =
    fieldId && assetsBySubtypeQuery?.data
      ? assetsBySubtypeQuery?.data?.find(
          (f: { fieldId: string }) =>
            (fieldId === "null" && !f.fieldId) || f.fieldId === fieldId
        )
      : null;

  const dataProp = type === "animal" ? "breed" : "subtype";
  const {
    resetGeodata,
    deleteInventoryItem,
    resetDeleteItemState,
    deleteInventoryItemState,
    saveInventoryItem,
    resetSaveItemState,
    saveInventoryItemState,
  } = useInventorySummaries({
    org,
    inventoryType: "assets",
    subtype: type,
    colorProp: type === "animal" ? "breedColor" : "color",
    dataProp,
    seasonId,
  });

  const {
    fetchList: fetchPlantList,
    listState: plantListState,
  } = useInventoryType({
    org,
    seasonId,
    type: "assets",
    subType: "plant",
    themeConfig: [
      {
        dataProp: "crop",
        colorProp: "color",
      },
    ],
  });

  const {
    fetchList: fetchFixedList,
    listState: fixedListState,
  } = useInventoryType({
    org,
    seasonId,
    type: "assets",
    subType: "fixed",
    themeConfig: [
      {
        dataProp: "fixedAssetType",
        colorProp: "color",
      },
    ],
  });

  const {
    fetchList: fetchAnimalList,
    listState: animalListState,
  } = useInventoryType({
    org,
    seasonId,
    type: "assets",
    subType: "animal",
    themeConfig: [
      {
        dataProp: "assetAnimalBreed",
        colorProp: "assetAnimalBreedColor",
      },
    ],
  });

  // let currentAssetTypes;
  let fetchListData;
  let listState;
  switch (type) {
    case "plant": {
      fetchListData = fetchPlantList;
      listState = plantListState;
      break;
    }
    case "fixed": {
      fetchListData = fetchFixedList;
      listState = fixedListState;
      break;
    }
    case "animal": {
      fetchListData = fetchAnimalList;
      listState = animalListState;
      break;
    }
    default: {
      fetchListData = () => {};
      listState = null;
    }
  }

  return (
    <AssetsStateContext.Provider
      value={{
        deleteInventoryItemState,
        saveInventoryItemState,
        subtypeId: subtypeObj?.subtypeId,
        subtypeName: subtypeObj?.subtype,
        fieldId,
        fieldName: fieldObj?.field,
        groupId,
        groupName,
        listBySeasonState: listState,
        fixedAssetTypes,
        assetGroups,
        assetEventTypes,
        isFetchingData:
          assetsBySubtypeQuery.isLoading || assetsByTypeQuery.isLoading,
      }}
    >
      <AssetsDispatchContext.Provider
        value={{
          deleteInventoryItem,
          saveInventoryItem,
          resetSave: resetSaveItemState,
          resetDelete: resetDeleteItemState,
          fetchListBySeason: fetchListData,
          createGroup: async (body) => {
            const res = await createGroup(`/${org}/assets/groups`, {
              method: "POST",
              body: JSON.stringify(body),
            });
            if (res && res.data && !res.isError) {
              const d = res.data;
              const newData = [{ ...d, value: d.id, label: d.name }];
              if (assetGroups) {
                newData.unshift(...assetGroups);
                newData.sort((a, b) => {
                  return a.name > b.name ? 1 : -1;
                });
              }
              resetGroups(newData);
            }
            return res;
          },
          resetAssetsGeodata: useCallback(() => {
            resetGeodata();
          }, [resetGeodata]),
        }}
      >
        {children}
      </AssetsDispatchContext.Provider>
    </AssetsStateContext.Provider>
  );
}

function useAssetsState() {
  const context = React.useContext(AssetsStateContext);
  if (context === undefined) {
    throw new Error("useAssetsState must be used within an AssetsProvider");
  }
  return context;
}

function useAssetsDispatch() {
  const context = React.useContext(AssetsDispatchContext);
  if (context === undefined) {
    throw new Error("useAssetsDispatch must be used within an AssetsProvider");
  }
  return context;
}

export { AssetsProvider, useAssetsState, useAssetsDispatch };
